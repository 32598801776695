import { navigate, PageProps } from 'gatsby';
import { FC, useEffect } from 'react';

const EspaceExtractUpdate: FC<PageProps> = function ({
  params: { espaceId, extractId },
}) {
  useEffect(() => {
    navigate(`/espaces/${espaceId}/notes/extracts/${extractId}/update`, {
      replace: true,
    });
  }, []);

  return null;
};

export default EspaceExtractUpdate;
